import  React from "react";
import { Container } from "react-bootstrap";
import '../Styles/Text_elems.css'
import TabsTest from "../components/workarized-slideshow";
import "../Styles/tabs-content.css"
import '../BackGround.css';
import '../Styles/MainpageTitle.css';
import Footer from "../components/Footer";
import DocSlides from "../components/DocSlides";
import AnCall from "../components/AnCall";
import EasyPay from "../components/EasyPay";
import {BsClock, BsGift, BsCreditCard, BsPersonCheck, BsFileEarmarkArrowDown} from 'react-icons/bs';
import ButnotS from "../Styles/butnotS";
import SixCards from "../components/SixCards";
import { DebtForm } from "../components/DebtForm";
import MainpageSliderP from "../components/MainPageSlider";
import Slideshow from "../components/Slideshow";
import MarqueePartners from "../components/Marquee";
import CalculatorR from "../components/CalculatorR";
import CalculatorO from "../components/CalculatorO";

const items = [
    {
      id : (
        1
      ),
      title: (
        <h3>Рассрочка</h3>
        ),
      bs: (
        <div>
          <BsClock className='BiTime'/>
        </div>
      ),
      contents: (
        <div className='tabContainer' id='CalculatorR'>
          <h2>Платите на выгодных Вам условиях с рассрочкой</h2>
          <CalculatorR/>
        </div>
      ),
    },
    {
      id : (
        2
      ),
      title: (
        <h3>Прощение долга</h3>
        ),
      bs: (
        <div>
          <BsGift className='BiTime'/>
        </div>
      ),
      contents: (
        <div className='tabContainer'>
          <h2>Спишем часть задолженности при условии разовой оплаты</h2>
          <CalculatorO />
        </div>
      ),
    },
    {
      id : (
        3
      ),
      title: (
        <h3>Оплата Онлайн</h3>
        ),
      bs: (
        <div>
          <BsCreditCard className='BiTime'/>
        </div>
      ),
      contents: (
        <div className='tabContainer'>
          <h2>Оплата долга онлайн без комиссии</h2>
          <p>Вам доступна оплата по Qr-коду, реквизитам или через терминал</p>
          <a href='/pay'>
            <ButnotS name='Подробнее' id='b3'></ButnotS>
          </a>
        </div>
      ),
    },
    {
      id : (
        4
      ),
      title: (
        <h3>Личный кабинет</h3>
        ),
      bs: (
        <div>
          <BsPersonCheck className='BiTime'/>
        </div>
      ),
      contents: (
        <div className='tabContainer'>
          <h2>Управление долгом в личном кабинете</h2>
          <p>Контролируйте задолженность 24/7.<br/>Здесь можно выбрать размер скидки и сумму платежа. Посмотреть остаток долга и проверить предыдущие операции</p>
          <a href='/login'>
            <ButnotS name='Подробнее' id='b4'/>
          </a>
        </div>
      ),
    },
    {
      id : (
        5
      ),
      title: (
        <h3>Справки</h3>
        ),
      bs: (
        <div>
          <BsFileEarmarkArrowDown className='BiTime'/>
        </div>
      ),
      contents: (
        <div className='tabContainer'>
          <h2>Документы о задолженности</h2>
          <p>Предоставляем подтверждение о наличии задолженности, справку о закрытии и другие документы по вашему запросу в течение 3 дней</p>
          <a href='/support'>
            <ButnotS name='Подробнее' id='b5'></ButnotS>
          </a>
        </div>
      ),
    },
]

const opYear = new Date().getFullYear() - 2014

const Facts = [
  {
    id: 1,
    li: `${opYear} лет`,
    p: <p>на рынке.<br/>работаем с 2014 года.</p>,
  },
  {
    id: 2,
    li: '1000',
    p: <p>клиентов получают<br/>рассрочку ежедневно</p>,
  },
  {
    id: 3,
    li: '100 000+',
    p: <p>клиентов исправили<br/>кредитную историю</p>,
  },
  {
    id: 4,
    li: '60+',
    p: <p>регионов охвата<br/>деятельности по России</p>,
  },
  {
    id: 5,
    li: 'c 2018',
    p: <p>года включены<br/>в реестр ФССП</p>,
  },
]

function Mainpage() {
    return (
      <React.Suspense>
        <div className="body" style={{overflow:'hidden'}}>
            <MainpageSliderP/>
            <DebtForm id='MainPageForm'/>
            <Container className='txxt' style={{marginBottom: "2rem", marginTop:'-5rem'}}>
                <ul className='tnt' >
                  {Facts.map((fact, i) => {
                    return (
                      <div key={Facts[i].id}>
                        <li className='tnt'>{fact.li}
                          {fact.p}
                        </li>
                      </div>
                    )
                  })}
                </ul>
            </Container>
            <Container className='container-column' id='tabs-ec'>
                <h1 className="txxt-h1">Попрощайтесь с долгами онлайн на своих условиях</h1>
                <hr style={{color:'wheat', width:'554px', marginBottom:'4rem'}}></hr>
                <TabsTest items={items} />
            </Container>
            <Container className='container-column' >
                <h1 className="txxt-h1">Взгляните, как мы поможем Вам начать жизнь без долгов</h1>
                <hr style={{color:'wheat', width:'622px', marginBottom:'4rem'}}></hr>
                <SixCards/>
            </Container>
            <div className='begeContainer'>
              <Container className="container-column">
                  <h1 className="txxt-h1" style={{color:'hsl(156 45% 11% / 0.75)'}}>Погасить обязательство</h1>
                  <hr style={{color:'hsl(156 45% 11% / 0.75)', width:'510px'}}></hr>
                  <p className="txxt" style={{marginLeft:'0rem', color:'hsl(156 45% 11% / 0.75)', fontSize:'large'}}>онлайн — легко:</p>
              </Container>
              <EasyPay></EasyPay>
            </div>
            <Container className="container-column" id='MPabout'>
                <h1 className="txxt-h1">О компании</h1>
                <hr style={{color:'white', width:'250px'}}></hr>
            </Container>
            <Container className="containerRow1" style={{marginBottom:"4.5rem"}}>
                <p className="doc-p">Экспресс Коллекшн – 
                клиентоориентированная компания. Более 8 лет помогаем людям решать вопросы с долгами. В 2018 году компания была сертифицирована Федеральной службой судебных приставов (Свидетельство № 2/18/18000-КЛ от 16.03.2018). Строго соблюдаем требования ФЗ 230 «О защите прав и законных интересов физических лиц при осуществлении деятельности по возврату просроченной задолженности». В своей деятельности придерживаемся этических норм и стандартов, разработанных Ассоциацией НАПКА, членом которой являемся с 2020 года.</p>
                <DocSlides />
            </Container>
            <div className="begeContainer1">
              <Container className='container-column' style={{zIndex:'5000'}}>
                <h1 className="txxt-h1" style={{color:'hsl(156 45% 11% / 0.75)', maxWidth:'100%'}}>Будьте в курсе новостей, познакомьтесь с партнёрами</h1>
                <hr style={{color:'hsl(156 45% 11% / 0.75)', width:'1154px', marginBottom:'0rem'}}></hr>
                <Slideshow/>
              </Container>
            </div>
            <MarqueePartners />
            <AnCall />
            <Footer /> 
        </div>
      </React.Suspense>
    )
}
  
export default React.memo(Mainpage)