import React from 'react'
import '../BackGround.css';
import RedditTextField from '../components/reddit-textfield';
import "../Styles/Debt_form.css"
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';
import ButnotP from '../Styles/butnotP';
import "../components/OfferSteps.css"
import { MdArrowForwardIos, MdOutlineDone } from 'react-icons/md'
import { Ex1, Ex2, Ex3, Ex4 } from '../components/Animated_icons.jsx';
import Footer from '../components/Footer';
import TabsTest from '../components/workarized-slideshow';
import {BsClock, BsGift, BsCreditCard, BsPersonCheck, BsFileEarmarkArrowDown} from 'react-icons/bs';
import ButnotS from '../Styles/butnotS';
import "../Styles/Text_elems.css"
import { Container, Spinner } from 'react-bootstrap';
import { publicFetch } from '../util/fetch';
import ScrollDialog from '../components/Dialog';
import CalculatorR from '../components/CalculatorR';
import CalculatorO from '../components/CalculatorO';

const items = [
  {
    id: (
      1
    ),
    title: (
      <h3>Рассрочка</h3>
      ),
    bs: (
      <div>
        <BsClock className='BiTime'/>
      </div>
    ),
    contents: (
      <div className='tabContainer'>
        <h2>Платите на выгодных Вам условиях с рассрочкой</h2>
        <CalculatorR />
      </div>
    ),
  },
  {
    id: (
      2
    ),
    title: (
      <h3>Прощение долга</h3>
      ),
    bs: (
      <div>
        <BsGift className='BiTime'/>
      </div>
    ),
    contents: (
      <div className='tabContainer'>
        <h2>Спишем часть задолженности при условии разовой оплаты</h2>
        <CalculatorO />
      </div>
    ),
  },
  {
    id: (
      3
    ),
    title: (
      <h3>Оплата Онлайн</h3>
      ),
    bs: (
      <div>
        <BsCreditCard className='BiTime'/>
      </div>
    ),
    contents: (
      <div className='tabContainer'>
        <h2>Оплата долга онлайн без комиссии</h2>
        <p>Вам доступна оплата по Qr-коду, реквизитам или через терминал</p>
        <a href='/pay'>
          <ButnotS name='Подробнее' id='b3'></ButnotS>
        </a>
      </div>
    ),
  },
  {
    id: (
      4
    ),
    title: (
      <h3>Личный кабинет</h3>
      ),
    bs: (
      <div>
        <BsPersonCheck className='BiTime'/>
      </div>
    ),
    contents: (
      <div className='tabContainer'>
        <h2>Управление долгом в личном кабинете</h2>
        <p>Контролируйте задолженность 24/7. Здесь можно выбрать размер скидки и сумму платежа. Посмотреть остаток долга и проверить предыдущие операции</p>
        <a href='/login'>
          <ButnotS name='Подробнее' id='b4'></ButnotS>
        </a>
      </div>
    ),
  },
  {
    id: (
      5
    ),
    title: (
      <h3>Справки</h3>
      ),
    bs: (
      <div>
        <BsFileEarmarkArrowDown className='BiTime'/>
      </div>
    ),
    contents: (
      <div className='tabContainer'>
        <h2>Документы о задолженности</h2>
        <p>Предоставляем подтверждение о наличии задолженности, справку о закрытии и другие документы по вашему запросу в течение 3 дней</p>
        <a href='/support'>
          <ButnotS name='Подробнее' id='b5'></ButnotS>
        </a>
      </div>
    ),
  },
]

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+7 (#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function Debt() {
  const [values, setValues] = React.useState({
    username: '',
    birthdate: '',
    textmask: '',
    contract: '',
  });
  const [debtSuccess, setDebtSuccess] = React.useState() 
  const [debtError, setDebtError] = React.useState()
  const [debtLoading, setDebtLoading] = React.useState(false)
  const [checked, setChecked] = React.useState(false)

  const handleChange = (event) => {
    event.preventDefault()
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange1 = (e) => {
    // e.preventDefault()
    const value = e.target.value
    setValues({
      ...values,
      [e.target.name]: value
    })
  };

  const submitCredentials = async credentials => {
    credentials.preventDefault()
    const Credentials = {
      username : values.username,
      birthdate: values.birthdate,
      textmask : values.textmask,
      contract : values.contract,
      pageId: 2
    }
    try {
      if (checked === true) {
        setDebtLoading(true)
        const {data} = await publicFetch.post(`api/formreqs`, Credentials)
      
        setDebtError('');
        setTimeout(() => {
          setDebtSuccess(data.message);
        }, 1100);
      } else {
        setDebtError(`Необходимо ознакомиться с Политикой компании в отношении обработки персональных данных`)
      }
    } catch (error) {
      setDebtLoading(false);
      const {data} = error.response;
      setDebtError(data.message);
      setDebtSuccess('');
    }
  }

  const handleBackClick = () => {
    setDebtLoading(false)
    setDebtSuccess(!debtSuccess)
  }

  return (
    <React.Suspense>
      <div className='imageContainer'>
        <h2>Узнать свой долг можно в любой момент. <br/> Ещё проще в личном кабинете.</h2>
        <p>В личном кабинете вас ждёт персональное предложение <br/> с возможностью списать до 50% от суммы долга</p>
        <div className='backToBack'/>
      </div>
      <div className='formGroupD' id='debtpage'>
        {!debtSuccess ? (
          <div style={debtSuccess ? {opacity:'0', transition: 'all 0.3s cubic-bezier(.165, .84, .44, 1)'} : {opacity: '1', transition: 'all 0.3s cubic-bezier(.165, .84, .44, 1)'}}>
            <label className='FormLabel' id='debtLabel'>Узнайте о наличии задолженности быстро и безопасно</label>
            <form className='ECformD' onSubmit={values => submitCredentials(values)}>
              <div >
                <RedditTextField id='name' onChange={handleChange} value={values.username} type='text' label='ФИО' required variant='filled' name='username' InputLabelProps={{ shrink: true }}/>
              </div> 
              <div>
                <RedditTextField id='date' label='Дата рождения' onChange={handleChange} value={values.birthdate} required type='date' InputLabelProps={{ shrink: true }} variant='filled' name='birthdate' InputProps={{ disableUnderline:true }} inputProps={{ min: "1950-05-01", max: "2023-05-04"}}/>
              </div> 
              <div>
                <RedditTextField variant='filled' required label='Контактный номер' InputLabelProps={{ shrink: true }} value={values.textmask} onChange={handleChange1} name='textmask' id='formatted-text-mask-input' InputProps={{inputComponent: TextMaskCustom, disableUnderline: true}}/>  
              </div> 
              <div>
                <RedditTextField variant='filled' name='contract' id='contract' value={values.contract} onChange={handleChange} InputLabelProps={{ shrink: true }} label='Номер кредитного договора'/>
              </div>
              <ButnotP type='submit' id='ButnotP2' name={debtLoading === true ? (<Spinner animation="border" style={{marginTop:'6px'}}/>) : (<span>Продолжить</span>)} style={{width:'200px', height:'59px', fontSize:'12pt', borderRadius:'5px'}}/> 
            </form>
            <div className='PrivacyRow'>
              <ScrollDialog checked={checked} setChecked={setChecked} id='DebtCheck'/>
              <span style={{wordWrap: "break-word", maxWidth:"600px"}}>{debtError}</span>
            </div>
          </div> 
        ) : (
          <div className='debtMessage' style={debtSuccess ? {opacity: '1'} : {opacity: '0'}}>
            <MdOutlineDone className='debtIcon'/>
            {debtSuccess}
            <button type='button' id='debtButtonBack' onClick={handleBackClick}>Вернуться назад</button>
          </div>
        )}
      </div>
      <div className='CenteredThings' id='CT1'>
        <div className='row2'>
          <div className='column'>
            <Ex1/>
            <h3>Узнайте, передан ли ваш долг в нашу компанию</h3>
          </div>
          <MdArrowForwardIos className='arrow' />
          <div className='column'>
            <Ex2/>
            <h3>Выберите подходящие условия и график оплаты</h3>
          </div>
          <MdArrowForwardIos className='arrow' />
          <div className='column'>
            <Ex3/>
            <h3>Совершите первый платёж со скидкой по графику</h3>
          </div>
          <MdArrowForwardIos className='arrow' />
          <div className='column'>
            <Ex4/>
            <h3>Получите полный набор документов и справку о погашении задолженности</h3>
          </div>
        </div>
      </div>
      <div className='CenteredThings' id='CT2'>
        <h1 className="txxt-h1">Попрощайтесь с долгами онлайн на своих условиях</h1>
        <hr style={{color:'wheat', width:'556px', marginBottom:'4rem'}}></hr>
        <Container className='container-column' style={{minWidth:'100%'}}>
          <TabsTest items={items}/>
        </Container>
      </div>
      <Footer/>
    </React.Suspense>
  )
}

export default React.memo(Debt)